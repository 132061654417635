<template>
	<div>
		<van-nav-bar
			title="開具收據"
			left-arrow
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		
		<div class="qjc-input-group">
			<van-field v-model="payer" placeholder="請填寫您的付款單位（個人）" />
			
			<van-button @click="receipt" class="qjc-bg-primary qjc-c-fff qjc-fts-36" round>提交申請</van-button>
		</div>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Field, Button, Toast } from 'vant'
	Vue.use(NavBar)
	   .use(Field)
	   .use(Button)
	   .use(Toast);
	   
	import { receipt } from "@/js/axios"
	
	export default{
		name: "userApplyInvoice",
		data (){
			return {
				payer: ''//付款單位
			}
		},
		methods: {
			receipt (){
				if(this.payer.trim() != ''){
					receipt({
						act: 1,
						id: this.$route.params.id,
						title: this.payer
					}).then(res => {
						if(res.status == 1){
							Toast.success(res.msg);
							//跳轉至收據詳情頁
							this.$router.push('/user/invoice/collection/'+res.data.id);
						}else{
							Toast.fail(res.msg);
						}
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.qjc-input-group{
		padding: 0.12rem 0.5rem;
		
		.van-field{
			border-bottom: 0.01rem solid #CCCCCC;
			padding-left: 0.1rem;
		}
		.van-button{
			width: 6.5rem;
			height: 0.88rem;
			line-height: 0.88rem;
			margin-top: 0.6rem;
		}
	}
</style>
